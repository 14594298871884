import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';

import styles from './style.module.css';

/**
    @param: className - use normal React className to add custom class to the strip
    @param: backgroundImageSrcUrl - link to share to use as background image
 */
class Strip extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.state.bgLoaded = false;
		this.state.thumbLoaded = false;
	}

	componentDidMount() {
		let bgImg = new Image();
		let thumbImg = new Image();

		if (this.props.backgroundImageSrcUrl) {
			bgImg.src = this.props.backgroundImageSrcUrl;
			bgImg.onload = () => {
				this.setState({ bgLoaded: true });
			};
		}

		if (this.props.backgroundImageThumbSrcUrl) {
			thumbImg.src = this.props.backgroundImageThumbSrcUrl;

			thumbImg.onload = () => {
				this.setState({ thumbLoaded: true });
			};
		}
	}

	render() {
		const children = React.Children.map(this.props.children, (child, index) => {
			return React.cloneElement(child, {
				index,
				lang: this.props.lang,
			});
		});
		let visibleBg = false;
		let visibleThumb = false;
		const backgroundImage = this.props.backgroundImageSrcUrl
			? this.props.backgroundImageOption
				? `${this.props.backgroundImageOption}, url(${this.props.backgroundImageSrcUrl})`
				: `url(${this.props.backgroundImageSrcUrl})`
			: (visibleBg = true);
		const backgroundImageThumb = this.props.backgroundImageThumbSrcUrl
			? `url(${this.props.backgroundImageThumbSrcUrl})`
			: (visibleThumb = true);

		return (
			<section className={styles.stripWrapper + ' ' + this.props.className}>
				<div className={styles.stripThumbWrapper}>
					<div
						className={
							styles.stripThumbAsBackgroud + ' ' + (this.state.thumbLoaded || visibleThumb ? styles.visible : null)
						}
						style={{
							backgroundImage: this.state.thumbLoaded ? backgroundImageThumb : null,
						}}
					/>
				</div>
				<div
					className={styles.stripImageAsBackgroud + ' ' + (this.state.bgLoaded || visibleBg ? styles.visible : null)}
					style={{
						backgroundImage: this.state.bgLoaded ? backgroundImage : null,
					}}>
					<div className={styles.stripContent + ' applicationWrapper ' + this.props.classNameChildren}>{children}</div>
				</div>
			</section>
		);
	}
}

Strip.propTypes = {
	children: PropTypes.node.isRequired,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Strip);
