// eslint-disable-next-line
import React, { Component } from 'react';
import { setTranslationLinks } from '../../services/SetTranslationLinks';

class SiteComponent extends Component {
	constructor(props, addSlug = false, options = { parent: '' }) {
		super(props);
		this.addSlug = addSlug;
		this.slugOptions = options;

		const translationPage = this.props.data.page.polylang_translations;

		const currentPage = this.addSlug
			? this.slugOptions.parent.length > 0
				? `/${this.props.state.lang}/${this.slugOptions.parent}/${this.props.data.page.slug}/`
				: `/${this.props.state.lang}/${this.props.data.page.slug}/`
			: `/${this.props.state.lang}/`;

		setTranslationLinks(
			this.props.state,
			this.props.reduxFunctions,
			currentPage,
			translationPage,
			this.addSlug,
			this.slugOptions
		);
	}

	render() {
		return <div />;
	}
}

export default SiteComponent;
