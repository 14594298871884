// Modules
import React from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
// Componets
import SiteComponent from '../../components/SiteComponent';
import Strip from '../../components/Strip';
import Layout from '../../components/Layout';
// Services
import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';
// Styles
import styles from './style.module.css';

/**
 * Template class for Page Wordpress Post Type
 */
class Page extends SiteComponent {
	constructor(props) {
		super(props, true);
	}
	render() {
		const currentPage = this.props.data.page;

		return (
			<Layout metadata={currentPage.yoast}>
				<Strip className={styles.box}>
					<h1 dangerouslySetInnerHTML={{ __html: currentPage.title }} />
					<div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
				</Strip>
			</Layout>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Page);

// Look here to more example: https://www.gatsbyjs.org/docs/graphql-reference/
export const pageQuery = graphql`
	query($id: String!) {
		page: wordpressPage(id: { eq: $id }) {
			title
			slug
			content
			date(formatString: "MMMM DD, YYYY")
			language: polylang_current_lang_code
			polylang_translations {
				id
				slug
				title
				polylang_current_lang_code
			}
			yoast {
				title
				metadesc
				metakeywords
				noindex_nofollow: meta_robots_nofollow
				canonical
				social_title: opengraph_title
				social_description: opengraph_description
				social_image: opengraph_image
			}
		}
	}
`;
