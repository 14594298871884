const setTranslationLinks = (
	state,
	reduxFunction,
	currentPage,
	translationPages,
	addSlug = false,
	options = { parent: '' }
) => {
	translationPages &&
		translationPages.length &&
		translationPages.forEach((item) => {
			if (
				!!!state.currentTranslationPages[currentPage] ||
				!!!state.currentTranslationPages[currentPage][item.polylang_current_lang_code]
			) {
				reduxFunction.addTranslationPage(
					currentPage,
					item.polylang_current_lang_code,
					addSlug
						? options.parent.length > 0
							? `/${item.polylang_current_lang_code}/${options.parent}/${item.slug}/`
							: `/${item.polylang_current_lang_code}/${item.slug}/`
						: `/${item.polylang_current_lang_code}/`
				);
			}
		});

	if (currentPage !== state.currentPage) {
		reduxFunction.setCurrentPage(currentPage);
	}
};

exports.setTranslationLinks = setTranslationLinks;
